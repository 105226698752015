import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import DocumentsDropdown from "../../../components/DocumentsDropdown";

const sIACSummaries_Content = [
    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "SIAC Newsletter"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [

            "The Outreach & Promotion Standing Committee created and disseminates a quarterly SIAC Newsletter. Select a date from the dropdown list to view newsletter first released September 2021.",

            <><DocumentsDropdown typeId="21" /><br/></>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: <>Meeting Summaries and Handouts
        <br/>   
        <br/>
        </>
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [
        <><b>Council Meeting Summaries</b> <br/><br/>
        The council generally meets monthly. Select a meeting date from the dropdown list below to view a summary of any meeting held from May 2013 forward.</>,
        <><DocumentsDropdown typeId="17" /><br/></>
        ]
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 50,
        content: [
        <><b>Handouts</b> <br/><br/>
        Select a meeting date from the dropdown list below to view handouts for any meeting held from June 2015 forward.</>,
        <><DocumentsDropdown typeId="16" /><br/></>
        ]
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 60,
        content: [
        <><b>Standing Committee Meeting Summaries</b> <br/><br/>
        Select a meeting date from the dropdown list below to view summaries of meetings held by standing committees from February 2015 forward.</>,
        <><DocumentsDropdown typeId="16" /><br/></>
        ]
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 70,
        content: [
        <><b>Social and Emotional Health Task Force Meeting Summaries</b> <br/><br/>
        Select a meeting date from the dropdown list below to view summaries of meetings held by this task force.</>,
        <><DocumentsDropdown typeId="20" /><br/></>
        ]
    },









];

export default sIACSummaries_Content;