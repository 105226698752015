import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";
import Links from "../../../components/Links";

const rIAC_Content = [
    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Overview"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "Regional Interagency Councils (RIACs) operate as the regional locus of accountability for the system of care, providing a structure for coordination, planning, and collaboration of services and supports to children and transition-age youth with or at risk of developing behavioral health needs and other challenges, and their families.",
            "This structure builds on existing resources of local public and private agencies and community partners within the community to support sustainable policy, practice, and system reform in order to improve the larger system of care for Kentucky’s children, transition-age youth, and their families, where they live, learn, work, and play.",
            "The system of care is not a model or a program that can be replicated, but instead an organizational framework that supports sustainable system change. The system of care philosophy specifies that the system of care should be:",
            <UnorderedList items={[
                "Youth- and family-driven",
                "Community-based",
                "Culturally and linguistically responsive",
                "Trauma-informed",
            ]} />
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "RIAC Membership"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: ["There are a total of 18 RIACs across the commonwealth. Each council is composed of members representing the following:",
    <UnorderedList items={[
        "Regional Community Mental Health Centers",
            "Administrative Office of the Courts",
            "Department for Community Based Services",
            "Division of Family Resource and Youth Services Centers (FRYSC)",
            "Office of Vocational Rehabilitation",
            "Kentucky Education Cooperatives/Special Education Services",
            "Department of Juvenile Justice",
            "Local health departments",
            "Parents (biological, adoptive, or relative caregivers with permanent legal custody) who are raising or have raised a child with mental health or co-occurring mental health and substance use challenges who has been or is a client of at least one service to address these challenges, prior to the age of 21",
            "Transition-age youth (16-25 years of age) who have a behavioral health disorder and who are receiving or have received a service to address mental health, substance use, or co-occurring mental health and substance use disorder before the age of 21"

    ]}/> ,
    "Collaboration is encouraged with other local public or private agencies that provide services and supports to children and transition-age youth with behavioral health needs."
]
    },
    {
        format: bodyHelperTypes.title,
        order: 50,
        content: "RIAC Duties"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 60,
        content: [
            "Duties of the RIAC include:",
            <UnorderedList items={[
                "Conduct regional system of care planning and operations through ongoing regional and/or local needs assessments across agencies to identify service gaps and/or needs.",
                "Coordinate system-level continuous quality improvement through review of local-, regional-, and state-level data to evaluate and strengthen the system of care",
                "Identify and develop system of care expansion opportunities to increase access and availability of high-quality services and supports.",
                "Promote system of care awareness across the community, including the adoption of system of care core values and guiding principles across all sectors",
                "Initiate and adopt interagency agreements as necessary for providing services and supports to children and transition-age youth with behavioral health needs by the agencies represented on the RIAC.",
                "Advise the SIAC regarding the system of care within the region.",
                "Ensure that one or more RIAC members participate in family accountability, intervention, and response (FAIR) teams established pursuant to KRS 605.035 and collaborate with FAIR teams as appropriate to improve or promote the system of care.",
            ]}/>,
        ]

    },
    {
        format: bodyHelperTypes.title,
        order: 70,
        content: "RIAC Meetings"

    },
    {
        format: bodyHelperTypes.paragraph,
        order: 80,
        content: "RIACs meet monthly and meetings are open to the public. If you are interested in attending a RIAC meeting, please reach out to the Local Resource Coordinator (LRC) of the RIAC to request meeting information. RIAC Leader contact information and meeting dates and times can be found in the RIAC Leader Contact List in Related Links."

    },
    {
        format: bodyHelperTypes.title,
        order: 90,
        content: "Resources for supporting Youth Representatives on RIACs:"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 100,
        content: [<><UnorderedList items={[

            "RIAC/SIAC Youth Rep video ",
            "Membership Guidance book",
            <><a href="https://dbhdid.ky.gov/dbh/documents/riac/RIACYouthAndParentMembershipApplication_2.1.22.pdf">RIAC Youth & Parent Representative Application</a></>,
            <><a href="https://designrr.page/?id=51182&token=2794574586&type=FP">SIAC/RIAC Parent/Youth Representative Guidance Document </a></>,
        ]} /></>,
    ]

    },
    






];

export default rIAC_Content;