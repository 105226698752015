import React from "react";
import bodyHelperTypes from "../../utils/BodyHelperTypes";
// import { Link } from "react-router-dom";
// import Links from "../../components/Links";
// import UnorderedList from "../../layouts/bodyLayouts/helpers/UnorderedList";
import KYWIDStrategicFramework from "../../assets/img/KYWIDStrategicFrameworkjpg.jpg"
import WIDScrollDown from "../../components/WIDScrollDown";
import Links from "../../components/Links";



const wID_Content = [

    {
        format: bodyHelperTypes.paragraph,
        order: 10,
        content: "Workforce Innovation & Development (WID) Collaborative is an open forum through which new and promising approaches to behavioral health, developmental and intellectual disabilities workforce challenges and opportunities can be designed, implemented and evaluated and existing strategies can be adapted and improved."
    },
    {
        format: bodyHelperTypes.title,
        order: 20,
        content: "About the WID Collaborative"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 30,
        content: [
            <>
                <b>Vision:</b><br />
                <b>Vision</b> of WID Collaborative is that the Kentucky behavioral health, developmental, and intellectual disabilities sectors will collaborate to explore, design, implement, and evaluate workforce practices that support service delivery that promotes the health, safety, and wellbeing of all Kentuckians.
            </>
            ,
            <>
                <b>Mission:</b><br />
                The <b>mission</b> of WID Collaborative is to establish an <b>open, participatory, and productive</b> collaborative that enables the review, design, implementation, and evaluation of policies and practices intended to improve recruitment, advancement, retention, performance and satisfaction of the behavioral health, developmental and intellectual disabilities workforce. The Collaborative will employ strategies to promote effective and efficient <b>brainstorming, coordination</b> and <b>communication</b> among key implementers as well as <b>alignment</b> with related initiatives.
            </>
            ,
            <>
                <b>Goal:</b><br />
                The overarching <b>goal</b> of WID is to <b>increase access to quality care by strengthening capacity of the behavioral health and intellectual and developmental disabilities workforce.</b>
            </>,

            <>
                <b>Framework:</b><br />
                <br />
                <img style={{ maxWidth: "100%", textAlign: "center !important", overflow: "hidden", }} src={KYWIDStrategicFramework} />


            </>,

            <>
                <span style={{ textAlign: "center" }}>

                    <b>
                        JOIN THE WID COLLABORATIVE TODAY! <br /> <br />
                        Our meetings are open to all who register. <br />
                        Please email <a href="mailto:vivian.pleasant@ky.gov">vivian.pleasant@ky.gov</a> if you would like to be invited to all WID meetings.
                        <br />

                        Response tab for email addresses – on bottom of every page

                    </b>
                    <br /><br />



                </span>

            </>
        ]

    },
    {
        format: bodyHelperTypes.title,
        order: 40,
        content: "Meetings and Events"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 41,
        content: [
            "Thank you for your interest and participation in the KY Department for Behavioral Health, Developmental and Intellectual Disabilities’ Workforce Innovation and Development (WID) Collaborative. Session recordings and meeting materials can be found via the below calendar link.",
            // "Our next WID Collaborative session will take place on Wednesday, July 10th from 1 to 2:30 PM EDT. ",
            <>Contact KDBHDID Executive Advisor Dr. Tena Robbins, <a href="mailto:Vestena.Robbins@ky.gov">Vestena.Robbins@ky.gov</a>, and Workforce Consultant Beth Kuhn, <a href="mailto:elizrkuhn@gmail.com">elizrkuhn@gmail.com</a>, for more information and see below for past meeting materials and recordings.</>
        ]


    },
    {
        format: bodyHelperTypes.title,
        order: 42,
        content: "Meeting Recordings"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 50,
        content: [

            <>
                <WIDScrollDown />
                <br />
            </>,

        ]

    },
    {
        format: bodyHelperTypes.title,
        order: 60,
        content: "General Information"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 70,
        content: [
            <>
            <br/>
            <b>Understanding Workforce Needs</b>

                <Links linkTypeID={805} />
            </>,
            <><b>Increasing the Supply of Professionals</b>

                <Links linkTypeID={806} />
            </>,
            <><b>Expanding the Reach of Existing Professionals</b>

                <Links linkTypeID={807} />
            </>,
            <><b>Addressing the Distribution of Professionals</b>

                <Links linkTypeID={808} />
            </>,
            <><b>Retaining High Quality Professionals</b>

                <Links linkTypeID={809} />
            </>,
            <><b>Redesign the BHDID Workforce for the Future</b>

                <Links linkTypeID={810} />
            </>


        ]

    }




];

export default wID_Content;