import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";
import Links from "../../../components/Links";

const hFW_Content = [
    
    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "What is Wraparound?"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "The National Wraparound Initiative (see Related Links) tells us that in recent years, Wraparound has been most commonly conceived of as an intensive, individualized care planning and management process.",
            "Wraparound is not a treatment per se. The Wraparound process aims to achieve positive outcomes for children and youth who are experiencing behavioral health concerns by providing a structured, creative, and individualized team planning process that, compared to traditional treatment planning, results in plans that are more effective and more relevant to the child/youth and family."
        ]

    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Wraparound Teams"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [
            "Wraparound teams are unique to each child/youth and family, consist of people who are closest to the family, and should include both service providers and natural supports. The child/youth and family are integral members of the Wraparound team and should take the lead in selecting other team members.",
            "High Fidelity Wraparound includes traditional services provided by multiple child-serving agencies (for example, child welfare, behavioral health, juvenile justice, special education), as well as informal and natural supports that are available or developed in the community (for example, county extension office, youth sports league, mentoring program, extended family, faith community)."
           
        ]

    },
    {
        format: bodyHelperTypes.title,
        order: 50,
        content: "How Wraparound Works"

    },
    {
        format: bodyHelperTypes.paragraph,
        order: 60,
        content: "Wraparound operationalizes the System of Care Values and Principles (see Related Links) at the individual child/youth, and family level. The research demonstrates that the best outcomes for children/youth and families are achieved when the utilization of Wraparound adheres most strictly to the model, that is, with a high degree of fidelity."

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 70,
        content: [
            "Wraparound was developed in the 1980s as a process to serve children/youth who were at high risk for being placed outside of their homes and communities as a result of their complex behavioral health needs. The team-based approach is aimed at keeping children/youth at home in their communities and out of residential facilities.",
            "In the late 1990s a group of national Wraparound experts and researchers created clearly defined values, elements, and expectations for High Fidelity Wraparound so that it could be done consistently across programs and states. This also made it easier to study the Wraparound process and outcomes for children/youth and families."
        ]

    },
    {
        format: bodyHelperTypes.title,
        order: 80,
        content: "Ten Principles of the Wraparound Process"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 90,
        content: [
            "One outcome of this work was the identification of 'Ten Principles of the Wraparound Process,' that guide the Wraparound process. National leaders have agreed that for Wraparound to be implemented with high fidelity, the process must adhere to the following principles:",
            <Links linkTypeID="135"/>,
            "Research since then has shown that Wraparound implemented with high fidelity to the model leads to the best outcomes for families with children and youth who have behavioral health challenges",
            "In January 2016, the Community Mental Health Centers began providing High Fidelity Wraparound through the Kentucky IMPACT program (see Related Links).",
            "DBHDID staff are currently working with the National Wraparound Implementation Center (see Related Links) to support High Fidelity Wraparound (HFW) (see Related Links) under Kentucky IMPACT within the Community Mental Health Centers (CMHCs). HFW Facilitators and HFW Supervisors participate in a HFW Learning Collaborative that includes ongoing technical assistance, coaching, and fidelity monitoring.",
            "Trained HFW Facilitators work with fewer children/youth than traditional Targeted Case Managers; however, the families partnering in HFW have more complex needs and require more coordination among formal and informal services and supports. The lower number of families allows the HFW Facilitators to spend more time with each family, supporting them in meeting their goals and becoming connected with community resources that will help them thrive. Children/youth will be determined eligible to partner in HFW via a multi-step process.",
            "Child/youth and family functioning are assessed across a variety of life domains on a regular basis to assist in determining the effectiveness of HFW in reducing behavioral health concerns and increasing connections within the community. ",
            "Information about High Fidelity Wraparound, including more details about the ten principles, can be found in the Resource Guide to Wraparound (see Related Links)."
        ]

    },
    
 



            

           
    


];

export default hFW_Content;