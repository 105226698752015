import IsNullOrWhiteSpace from "./IsNullOrWhiteSpace";


function ContactInfoPerson({ email, name, title, phone }) {

    return (
        <>
            <li>
                <i className="fa-li fa fa-user" aria-hidden="true"></i>
                <a title={name + ": "+ email} className="person" href={"mailto:" + email}>
                    <IsNullOrWhiteSpace arg={title} notNullComponent={<>{title}<br /></>} />
                    {name}<br />
                </a>
                <a className="phone" href={"tel:" + phone}>{phone}</a>
            </li>
        </>


    );
}

export default ContactInfoPerson;