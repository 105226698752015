import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";

const pIP_Content = [

    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            "Partners in Prevention (PIP) is an initiative to help create connections among local community partners to address substance use/misuse and suicide prevention. The PIP toolkit includes resources and information for multiple community sectors to increase awareness and promote evidence-based prevention strategies.  It provides support for substance use/misuse and suicide prevention and aims to help reduce the stigma related to behavioral health.",
            <>
                The goals of Partners in Prevention are to:
                <ul>
                    <li>Connect with the sectors identified below to increase knowledge and awareness of their roles in substance use/misuse and suicide prevention and build community capacity to address those issues locally. </li>
                    <li>Increase protective factors while decreasing risk factors among local communities. </li>
                    <li>Increase the capacity of Regional Prevention Centers (RPCs) to work with the identified sectors and create working partnerships.</li>
                </ul>
            </>,
            <>
                Quick facts:
                <ul>
                    <li>Partners in Prevention was developed by Comprehend Regional Prevention Center in 2017. </li>
                    <li>In 2019, it was adopted by the Kentucky Regional Prevention Centers as a statewide project.</li>
                    <li>PIP works with the 12 sectors identified in the Drug Free Communities Grant application as vital to local prevention efforts: <br />
                        <ul>
                            <li>Youth</li>
                            <li>Parents</li>
                            <li>Law Enforcement</li>
                            <li>Schools</li>
                            <li>Businesses</li>
                            <li>Media</li>
                            <li>Youth-serving organizations</li>
                            <li>Civic and volunteer groups</li>
                            <li>Healthcare professionals</li>
                            <li>State, local, and tribal agencies with expertise in substance misuse</li>
                            <li>Other organizations involved in reducing substance misuse</li>
                        </ul>
                    </li>
                    <li>What is DFC? How a community can find out more information: <br />
                        <a href='https://www.cdc.gov/drugoverdose/drug-free- communities/about.html'>https://www.cdc.gov/drugoverdose/drug-free- communities/about.html</a>
                    </li>
                </ul>
            </>,
            <>
                Potential Warning signs of Substance Use/Misuse
                <ul>
                    <li>Increased absence from work or school</li>
                    <li>Any unexplained changes in behavior</li>
                    <li>Changes in appetite or sleep pattern</li>
                    <li>Unusual or increased irritability </li>
                    <li>Financial problems  </li>
                    <li>Changes in personal grooming </li>
                    <li>Relationship problems</li>
                </ul>
            </>,
            <>
                Potential Warning Signs of Suicide
                <ul>
                    <li>Suicide threats </li>
                    <li>Previous suicide attempts </li>
                    <li>Misuse of alcohol and other substances</li>
                    <li>Sudden changes in behavior</li>
                    <li>Prolonged, untreated depression</li>
                    <li>Making final arrangements </li>
                    <li>Giving away prized possessions</li>
                    <li>Purchasing a gun or stockpiling pills</li>
                </ul>
            </>
        ]
    },
];

export default pIP_Content;