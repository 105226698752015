import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const commissionDocuments_Content = [

    // {
    //     format: bodyHelperTypes.title,
    //     order: 10,
    //     content: "Data Information"
    // },
    // {
    //     format: bodyHelperTypes.paragraphs,
    //     order: 20,
    //     content: [
    //         <><Links linkTypeID={112} /></>
    //     ]
    // },
];

export default commissionDocuments_Content;