import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";
import Links from "../../../components/Links";

const children = [
    {
        format: bodyHelperTypes.title,
        order: 10,
        content: <>About Us</>

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "The Children’s Mental Health and Recovery Services Branch is responsible for the administration of services and supports for children and youth who have or are at-risk of developing behavioral health concerns (including both mental health and substance use), and their families. These services are primarily managed through contracts with the regional Community Mental Health Centers and other entities areas to deliver an array of behavioral health services and supports. Additionally, the branch manages contracts with entities that provide training, consultation, and technical assistance in various areas, such as family and youth leadership, evidence-based practices, and other system of care activities.  ",
            "Branch staff works collaboratively with contracted agencies to provide consultation and technical assistance to increase the capacity of providers to deliver a continuum of behavioral health care that includes promotion, prevention, early intervention, treatment, and recovery services and other support.",
            "Additionally, staff works with specific state and regional initiatives, including but not limited to:",
            <UnorderedList items={[
                "Early Childhood Mental Health",
                "High-fidelity Wraparound",
                "Peer Support",
                "Transition Age Youth",
                "Trauma-informed Care",
                "School-based Behavioral Health",
                "State and Regional Interagency Councils",
                "System of Care Expansion and Sustainability",
                "Youth and Family Leadership Development",
                "Youth Substance Use Prevention and Treatment",
            ]} />
        ]

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 21,
        content: [
            
            "The branch adheres to and operates in accordance with the values and guiding principles of the system of care approach (see Related Links), as set forth by the federal Center for Mental Health Services within the Substance Abuse and Mental Health Services Administration (see Related Links)."
        ],

    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Program Areas",

    },
    {
        format: bodyHelperTypes.paragraph,
        order: 40,
        content: "The Children’s Mental Health and Recovery Services Branch staff is responsible for the following program areas:",

    },
    {
        format: bodyHelperTypes.paragraph,
        order: 50,
        content: <><Links linkTypeID="222"  /></>
    },

];

export default children;