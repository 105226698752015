import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import KSAPImage from '../../../assets/img/sud/KPSAP.png';

const kSAP_Content = [


    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            <>{/* <img style={{ maxWidth: "100%", overflow: "hidden", }} className='rounded mx-auto d-block' src={KSAPImage} alt=""></img> */}</>,

            <>
                <b>Vision:   Every military-connected youth in Kentucky receives what they need to succeed.</b>
            </>,
            <>
                <b>Mission: Instill a sense of connection and strengthen resilience within Kentucky’s military- connected youth.</b>
            </>,
            "The Purple Star Award is a state-sponsored recognition for individual schools’ dedication and support of military-connected youth. The Program focuses on strengthening the resilience of military-connected youth and ensuring that their social, emotional, and academic needs are met. The program was developed by the Ohio Department of Education in 2018.",
            "There are over 50,000 military connected youth in Kentucky. Military-connected youth can be affected by their family member’s deployments and military service. These youth face a distinct set of challenges that increase their risk of developing behavioral health problems and substance use disorders.",
            "Purple Star Schools provide a caring school climate, help increase student engagement, and encourage involvement from parents and other adults in the school and community. The Purple Star Award designation lets military parents know whether they are active duty, National Guard, Reserves, or Veterans, that the school is dedicated to helping their child gain the educational skills necessary to be college-, workforce-, and life-ready. The program is supported by the US Department of Defense, Military Child Education Coalition, and the Military Interstate Children’s Compact Commission. Currently forty-two states implement the program in their schools and school districts.",
            " The Kentucky Purple Star Award Program is a partnership involving twenty-five state agencies, military service providers, Kentucky National Guard, Fort Knox, Fort Campbell, and Kentucky’s Regional Prevention Centers.",
            <>
                The four goals of the Kentucky Purple Star Award Program are to:

                <ol>
                    <li>Create and sustain a statewide support system within schools and communities that work together to build resilience and support for military-connected youth in Kentucky.</li>
                    <li>Increase protective factors to help prevent substance use and behavioral health challenges among military-connected youth in Kentucky.</li>
                    <li>Partner with schools and communities to foster diverse, inclusive, and supportive environments for military-connected youth and families. </li>
                    <li>Educate the schools and community on the challenges faced by military families.</li>

                </ol>
            </>

        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 20,
        content: "Outcomes"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 30,
        content: [
            <>
                A 2022 national evaluation <a href='https://www.militarychild.org/upload/images/Purple%20Star%20Schools/2022_602_PSS_CPRL_Report.pdf'>report</a> by the Center for Public Research and Leadership found that:

                <ul>
                    <li>66 % of military families believe that the Purple Star program has a positive impact on military-connected students because of school staff’s increased awareness and special consideration of the unique social and emotional challenges that military-connected students often face.</li>
                    <li>63.3% of military families report that their children are academically “Supported” or “Very Supported” by the Purple Star program. They cite the importance of school staff understanding of the unique academic challenges military-connected students often face, which is fostered by the Purple Star program.</li>
                    <li>71.5% of families report that the Purple Star Program was either an “Important” or “Very Important” factor in their school selection.</li>
                    <li>Military-connected students report that it is easier for them to make friends at a Purple Star school as compared to non-Purple Star schools. </li>
                </ul>
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 40,
        content: "How to Become a Purple Star School"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 50,
        content: [
            <>{/* <img style={{ maxWidth: "100%", overflow: "hidden", }} className='rounded mx-auto d-block' src={KSAPImage} alt=""></img> */}</>,
            <>
                Schools must submit an application in which they agree to:
                <ul>
                    <li>Designate at least one trained staff to become the Purple Star Liaison </li>
                    <li>Host at least one annual military recognition event during the school year</li>
                    <li>Post local, state, and federal resources for military families on the school website. </li>
                </ul>
            </>,
            <>
                The submission deadline for the fall is September 15th. The deadline for the spring is February 15th. The application can be accessed at the link below: <br />

                <a href='http://www.kypurplestar.org/purple-star-award-application-2/'>http://www.kypurplestar.org/purple-star-award-application-2/</a>
            </>,
            <>
                For more information on the Kentucky Purple Star Program, please visit: <br />

                <a href='https://www.kypurplestar.org/'>https://www.kypurplestar.org/</a> Or contact Sarah Flowers <a href='mailto:sarah.jemison@ky.gov'>sarah.jemison@ky.gov</a> or Steve Cambron <a href='mailto:steve.cambron@ky.gov'>steve.cambron@ky.gov</a>.
            </>
        ]
    }
];

export default kSAP_Content;