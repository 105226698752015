import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";
import Links from "../../../components/Links";

const kYImpact_Content = [
    
    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            "Kentucky IMPACT (Interagency Mobilization for Progress in Adolescent and Child Treatment) was created as a statewide service coordination model in 1990. It was based on the work of a pilot program (Bluegrass IMPACT) and implemented in accordance with System of Care values and principles.",
            "IMPACT was established as a coordinated, interagency approach to service delivery for children/youth with serious emotional disabilities (SED) and their families. The model provided services not traditionally available, such as mentoring, school-based services, and intensive in-home therapy, as well as flexible funding for informal supports such as community activities, family support, and after-school and summer activities",
            "The overall goal of Kentucky IMPACT is to prevent children/youth with SED from being placed outside of their homes and to provide support and assistance to those who were transitioning home from such residential placements. Kentucky IMPACT has embraced the Wraparound process since its inception. In fact, the Kentucky IMPACT program was one of the first statewide Wraparound initiatives in the country.",
            "Historically, Targeted Case Management (TCM) (see Related Links), delivered in accordance with the values of Wraparound, has been the key component of Kentucky IMPACT by which Wraparound for children and youth has been implemented. Since the inception of Kentucky IMPACT, science related to Wraparound implementation has progressed dramatically.",
            "DBHDID and KY Partnership for Families and Children (KPFC) staff are currently working with the National Wraparound Implementation Center (see Related Links) to support High Fidelity Wraparound (HFW) (see Related Links) under Kentucky IMPACT within the Community Mental Health Centers (CMHCs). HFW Facilitators and HFW Supervisors participate in a HFW Learning Collaborative that includes ongoing technical assistance, coaching, and fidelity monitoring.",
            "Trained HFW Facilitators will work with fewer children/youth than traditional Targeted Case Managers; however, the families partnering in HFW have more complex needs and require more coordination among formal and informal services and supports. The lower caseload allows the HFW Facilitators to spend more time with each family, supporting them in meeting their goals and becoming connected with community resources that will help them thrive. Children/youth are determined eligible to partner in HFW via a multi-step process.",
            "Child/youth and family functioning are assessed across a variety of life domains on a regular basis to assist in determining the effectiveness of HFW in reducing behavioral health concerns and increasing connections within the community.",
            
        ]

    },
    
 



            

           
    


];

export default kYImpact_Content;