import React, { useEffect, useState } from 'react';
import { Form, FormGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios';
import kyCountyMap from '../assets/img/KYCountymap.png';

function ProviderMap({ formData, setFormData, onSubmit, onReset, noResults }) {
    const [counties, setCounties] = useState([]);
    const [validationMessage, setValidationMessage] = useState("");
    const [loading, setLoading] = useState(false); // To manage loading state

    useEffect(() => {
        const fetchCounties = async () => {
            try {
                //const response = await axios.get('https://localhost:7245/providers/counties');
                //const response = await axios.get('https://providerdirectory.dbhdidtest.ky.gov/api/providers/counties');
                const response = await axios.get('https://dbhdidtest.ky.gov/provdirapi/providers/counties');
                //const response = await axios.get('https://dbhdid.ky.gov/provdirapi/providers/counties');
                //console.log('Counties:', response.data); // Debug log
                setCounties(Array.isArray(response.data) ? response.data : []);
            } catch (error) {
                console.error('Error fetching counties:', error);
            }
        };
        fetchCounties();
    }, []);

    const handleCountyChange = (e) => {
        const selectedCounties = Array.from(e.target.selectedOptions, option => option.value);
        setFormData(prev => ({ ...prev, counties: selectedCounties, isStateWide: selectedCounties.length === counties.length }));
    };

    const handleMapClick = (countyDesc) => {
        const newSelection = formData.counties.includes(countyDesc) ?
            formData.counties.filter(county => county !== countyDesc) :
            [...formData.counties, countyDesc];
        setFormData(prev => ({ ...prev, counties: newSelection, isStateWide: newSelection.length === counties.length }));
    };

    const renderTooltip1 = (props) => (
        <Tooltip id="button-tooltip" {...props} className="tooltip-custom">
            None of the counties selected equivalent to statewide.
        </Tooltip>
    );

    const renderTooltip2 = (props) => (
        <Tooltip id="button-tooltip" {...props} className="tooltip-custom">
            To choose your desired counties, hold down Ctrl for PC or Command on MAC while making your selections.
        </Tooltip>
    );

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (
            (!formData.counties || formData.counties.length === 0) &&
            (!formData.groups || formData.groups.length === 0) &&
            (!formData.services || formData.services.length === 0) &&
            (!formData.otherProviders || formData.otherProviders.length === 0)
        ) {
            setValidationMessage("Please select your desired options.");
        } else {
            setValidationMessage("");
            setLoading(true); // Set loading to true before the API call

            try {
                await onSubmit(formData);
            } finally {
                setLoading(false); // Set loading to false after the API call
            }
        }
    };

    return (
        <div>
            <div className="image-map">
                <img src={kyCountyMap} alt="Kentucky County Map" useMap="#countyMap" />
                <map name="countyMap">
                    {Array.isArray(counties) && counties.map((county) => (
                        <area key={county.countyId} alt={county.countyDesc} title={county.countyDesc} coords={county.hotSpots} shape="poly" onClick={() => handleMapClick(county.countyDesc)} className="county-area" />
                    ))}
                </map>
            </div>

            <Form onSubmit={handleFormSubmit}>                                                                        
                <FormGroup className="form-group provider-map-section">
                    <Form.Check
                        type="checkbox"
                        label={
                            <>
                                <strong>Statewide
                                <OverlayTrigger
                                    placement="auto"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip1}
                                >
                                    <span className="info-icon">i</span>
                                </OverlayTrigger>
                                </strong>
                            </>
                        }
                        checked={formData.isStateWide}
                        onChange={(e) => setFormData(prev => ({
                            ...prev,
                            isStateWide: e.target.checked,
                            counties: e.target.checked ? counties.map(county => county.countyDesc) : []
                        }))}
                    />
                </FormGroup>

                <FormGroup className="form-group provider-map-section">
                    <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip2}
                    >
                        <strong>Counties:<span className="info-icon">i</span><br /></strong>
                    </OverlayTrigger>
                    <select multiple={true} value={formData.counties} onChange={handleCountyChange} className="county-select">
                        {Array.isArray(counties) && counties.map((county) => (
                            <option key={county.countyId} value={county.countyDesc}>{county.countyDesc}</option>
                        ))}
                    </select>
                    <br />
                    {formData.isStateWide ?
                        <span className="statewide-selection">All</span>
                        :
                        (formData.counties.length > 0 &&
                            <span className="county-selection">Selected: {formData.counties.join(', ')}</span>
                        )
                    }
                </FormGroup>

                <div className="form-actions">
                    <button type="button" className="btn btn-primary btn-custom" onClick={onReset}>Reset</button>
                    &nbsp;
                    <button className="btn btn-primary btn-custom" type="submit" disabled={loading}>
                        {loading ? "Searching..." : "Submit"}
                    </button>
                    {validationMessage && (
                        <div className="validation-message">
                            {validationMessage}
                        </div>
                    )}
                    {noResults && !validationMessage && (
                        <div className="no-results-message">
                            No results for your search criteria.
                        </div>
                    )}
                </div>
            </Form>
        </div>
    );
}

export default ProviderMap;
